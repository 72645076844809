.Laptop-view{
    /* background: linear-gradient(180deg, rgba(2,1,13) 16%, rgba(0,0,0,1) 54%, rgb(0, 0, 0) 99%);
    color: white; */
    height: 80px;
    width: 100%;
    position: fixed;
    /* display: inline; */
    z-index: 999;
}

.drawer-box{
    background-color: black;
    color: white;
}


.mobileDiv{
    display: none;
}

@media all and (min-width: 624px) and (max-width: 1040px){
    .Laptop-view{
        display: none;
    }

    .mobileDiv{
        display: block;
        position: fixed;
    }

}
@media all and  (max-width: 624px){
    .Laptop-view{
        display: none;
    }

    .mobileDiv{
        display: block;
        position: fixed;
        width: 90vw;
        z-index: 999;
    }

}