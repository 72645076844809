.popup {
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* z-index: 999999999999999999; */
  }
    
  .popup-content {
    position: absolute;
    top: 50%;   
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
    background-color: rgb(232, 232, 232);
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .popup.active {
    display: block;
  }
  
  @media all and (min-width: 624px) and (max-width: 1040px){
.popup{
  width: 100%;
  height: 100%;
}
  
.popup-content {
  position: absolute;
  top: 50%;   
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  background-color: rgb(232, 232, 232);
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

}
@media all and  (max-width: 624px){
  .popup-content {
    position: absolute;
    top: 50%;   
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: rgb(232, 232, 232);
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
}