.MainBox{
  text-align: center;
    background-image: url('./Images/mainImage.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}


/* styles.css */




/* background: rgb(188,156,209);
background: linear-gradient(0deg, rgba(188,156,209,1) 1%, rgba(110,104,115,1) 12%, rgba(74,74,74,1) 30%, rgba(0,0,0,1) 100%); */