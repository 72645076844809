.Link_btns-dark{
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}
.Link_btns-dark::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width .3s;
  background: rgb(250, 250, 250);
}
.Link_btns-dark:hover::after{
  width: 100%;
}
.Link_btns{
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}
.Link_btns::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width .3s;
  background: #10257f;
}
.Link_btns:hover::after{
  width: 100%;
}
  
  @keyframes rotateImage {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  .button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    overflow: hidden;
  }
  
.button::before {
    content: "Explore";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    color: white;
    transition: height 0.5s ease;
  }
  
  .button:hover::before {
    height: 100%;
  }
  


  .scrolling-text1 {
    width: 20%;
    overflow: hidden;
    margin: auto;
    /* color: white; */
    margin-top: -15px;
    padding-bottom: 10px;
  }
  
  .scrolling-text1 p {
    white-space: nowrap;
    animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .social-icons-home{
    display: block;
  }
  

@media only screen and (max-width: 640px) {

.social-icons-home{
  display: none;
}


}
