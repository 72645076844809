.container{
    width: 100%;
    margin: auto;
    padding: 40px;
    text-align: left;
    background-color: #eef4fe;
    /* margin-top: 100px; */
    z-index: 999999999;
}
@media screen and (max-width:1180px) and (min-width:760px) {
    .container{
        width: 100%;
        margin: auto;
        padding: 40px;
        text-align: left;
        background-color: #eef4fe;
        /* margin-top: 100px; */
    }
}
@media screen and (max-width:759px) and (min-width:200px) {
    .container{
        width: 100%;
        margin: auto;
        padding: 40px;
        text-align: left;
        background-color: #eef4fe;
        /* margin-top: 100px; */
    }
}