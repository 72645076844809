.content {
    opacity: 1;
    transition: opacity 0.5s ease; 
}
  
.content.hidden {
    opacity: 0;
}

/* Scroll Animation */



.scrolling-text {
    position: relative;
    display: inline-block;
    transition: transform 0.5s ease;
    cursor: pointer;
  }
  
  .scrolling-text:hover {
    transform: rotateX(360deg);
  }


.footerLogo{
color: blue;
cursor: pointer;
font-size: 32px;
margin-bottom: 32px;
}
.footerLogo:hover{
/* animation: fadeUP 1s; */
transform: scale(1.7);
transition: 0.1s;
/* transition: opacity 1s ease; */
}

.custom-scrollbar {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #333333 #f5f5f5;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 5px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }